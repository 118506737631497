// Global packages or components
import { useLoaderData } from '@remix-run/react'
import { getFixedPage } from '~/sanity'
import { getMeta } from '~/utils'
import { Blocks } from '~/components'

// Types
import type {
  MetaFunction,
  LoaderFunction,
  LoaderFunctionArgs,
} from '@remix-run/node'

// Route loader
export const loader: LoaderFunction = async ({
  request,
}: LoaderFunctionArgs) => {
  const env = process.env
  const url = new URL(request.url)

  // Sanity fetch
  const data = await getFixedPage({
    dataset: env?.ENVIRONMENT,
    projectId: env?.SANITY_PROJECT,
    name: 'home',
  })

  // Page not found
  if (!data) {
    throw new Response('Not Found', {
      status: 404,
    })
  }

  // Return data
  return Response.json({
    seo: data?.seo,
    blocks: data?.flexibleContent,
    path: url?.pathname,
  })
}

// Route meta
export const meta: MetaFunction = ({ matches, data }) => {
  return getMeta({
    matches,
    data,
  })
}

// Route view
export default function Index() {
  const { blocks } = useLoaderData<typeof loader>()

  return (
    <main>
      <Blocks blocks={blocks} />
    </main>
  )
}
